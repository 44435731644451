import { useTheme } from 'styled-components';
import { styleCategoryMap } from '../utils/styleCategoryMap';
import { SHORTHAND_STYLE_PROPS_MAP } from '../utils/shorthandStylePropsMap';
const MARGIN_PROPERTIES = new Set(['margin', 'marginBlock', 'marginBlockStart', 'marginBlockEnd', 'marginInline', 'marginInlineStart', 'marginInlineEnd']);

/**
 * Converts a provided CSS property and value to a CSS object.
 * @param key - The CSS property.
 * @param value - The CSS value.
 * @returns An object of CSS properties and values.
 */
const getCSSStylesForValue = (key, value) => {
  // If the key is a shordhand property, create a new object with the longhand properties where the same value is applied to each property.
  if (key in SHORTHAND_STYLE_PROPS_MAP) {
    const longhandKeys = SHORTHAND_STYLE_PROPS_MAP[key];
    const longhandStyles = {};
    longhandKeys.forEach(longhandKey => {
      longhandStyles[longhandKey] = value;
    });
    return longhandStyles;
  }

  // If the key isn't a shorthand property, return the key-value pair directly.
  return {
    [key]: value
  };
};

/**
 * Handles special case values for style properties that should accept
 * specific values outside of the theme system. Unlike properties such as
 * width/height which can accept any valid CSS value, some properties like
 * margin should only accept specific values beyond their theme tokens
 * (e.g., "auto").
 *
 * This function centralizes the logic for these constrained special cases,
 * making it easier to maintain and extend these exceptions to the theme system.
 *
 * @param key - The style property to check. Currently limited to margin-related properties.
 * @param value - The value to check for special handling.
 * @returns A CSS styles object if the value should be specially handled, null otherwise.
 *
 * @example
 * getSpecialCaseValue('marginInline', 'auto') // Returns { marginInline: 'auto' }
 * getSpecialCaseValue('marginBlock', '200') // Returns null (handled by theme system)
 */
const getSpecialCaseValue = (key, value) => {
  // Handle margin 'auto' case, since this value isn't a token in the theme.
  if (MARGIN_PROPERTIES.has(key) && value === 'auto') {
    return getCSSStylesForValue(key, 'auto');
  }

  // No special case applied
  return null;
};

/**
 * This hook maps style properties provided to components (e.g. Text, View,
 * Flex) to their corresponding CSS values defined within a theme.
 * For properties that do not correspond to a theme category, it uses the
 * value directly without theme validation.
 *
 * @param styleProps - The style properties provided to the component.
 * @param opts - An options object for customizing styles after initial computation.
 *
 * @returns A new object containing valid CSS properties and their values. E.g:
 *
 * `<View padding="1000">...</View>`
 *
 * Here, the `padding` prop is looked up in the `styleCategoryMap` object
 * to find the corresponding theme category, which is `space` in this case.
 * The padding value `1000` is then looked up in the `space`
 * category of the theme object, and the corresponding value is returned.
 * The object returned from the function would then be: `{ padding: '40px' }`.
 */
export const useMapStylePropsToCSS = (styleProps, opts = {}) => {
  const theme = useTheme();

  // Temp type to satisfy TypeScript. Will be `T` at the end.
  let cssStyles = {};
  Object.keys(styleProps).forEach(propKey => {
    const key = propKey;
    const value = styleProps[key];
    if (value) {
      // Check for special cases first
      const specialCaseStyles = getSpecialCaseValue(key, value);
      if (specialCaseStyles) {
        cssStyles = Object.assign({}, cssStyles, specialCaseStyles);
        return;
      }

      // Necessary type cast because the `styleCategoryMap` object contains
      // only all possible style props for which token values have been defined
      // in the design system. In cases where `key` is a style property without
      // tokens defined, like `width`, TypeScript would throw an error.
      const themeCategory = styleCategoryMap[key];

      // When a style property doesn't have a corresponding theme category,
      // like `width`, we use the value directly without theme validation.
      if (!themeCategory) {
        cssStyles = Object.assign({}, cssStyles, getCSSStylesForValue(key, value));
      } else {
        const themeCategoryStyles = theme[themeCategory];

        // Otherwise, we know that the style property has a corresponding
        // theme category, so we look up the token value in the theme object.
        if (themeCategoryStyles[value]) {
          cssStyles = Object.assign({}, cssStyles, getCSSStylesForValue(key, themeCategoryStyles[value]));
        }
      }
    }
  });
  if (opts.customizeStyles) {
    cssStyles = opts.customizeStyles({
      theme,
      styleProps,
      cssStyles: Object.assign({}, cssStyles)
    });
  }
  return cssStyles;
};
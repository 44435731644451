import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["typography"];
/**
 * Creates a partial object containing the base typography styles for each
 * element rendered by `Text` that should have default typography styles.
 *
 * @param theme - The current theme object.
 * @returns The partial object with base typography styles.
 */
const getBaseTypographyStyles = (theme, as) => {
  const {
    typography
  } = theme;
  const baseTypographyStyles = {
    p: typography['body-100'],
    small: typography['detail-200'],
    strong: typography['heading-100'],
    code: typography['code-100'],
    h1: typography['heading-600'],
    h2: typography['heading-500'],
    h3: typography['heading-400'],
    h4: typography['heading-300'],
    h5: typography['heading-100'],
    h6: typography['heading-50']
  };
  return baseTypographyStyles[as];
};

/**
 * Customizes the styles for the `Text` component based on the `as` prop.
 *
 * @param as - The DOM element to render for `Text`.
 * @param opts - An options object provided by the `useMapStylePropsToCSS`
 * hook to further customize the final styles object.
 * @returns The customized CSS styles object that will be passed to the
 * styled component rendered by `Text`.
 */
export const customizeStyles = (as, opts) => {
  const {
    theme,
    cssStyles
  } = opts;

  // The `Text` component sets some default typography values for some
  // elements, but they can be overriden by the user providing their own
  // `typography` token which by this point has been resolved with its
  // corresponding value in the theme object
  const {
      typography = {}
    } = cssStyles,
    restCssStyles = _objectWithoutPropertiesLoose(cssStyles, _excluded);
  switch (as) {
    // Elements without default typography styles
    case 'span':
    case 'div':
    case 'legend':
    case 'label':
    case 'em':
      {
        return Object.assign({}, typography, restCssStyles);
      }

    // Elements with default typography styles
    case 'p':
    case 'small':
    case 'strong':
    case 'code':
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
      {
        const baseTypographyStyles = getBaseTypographyStyles(theme, as);
        return Object.assign({}, baseTypographyStyles, typography, restCssStyles);
      }
    default:
      {
        const __exhaustiveCheck = as;
        throw new Error(`Unhandled \`as\` argument:\n ${as}`);
      }
  }
};
export const generateTextTruncateStyles = truncate => {
  /**
   * Note that we should move to non-vendor prefixed properties once line-clamp
   * is standardized [MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp).
   * We should also consider updating the use of '-webkit-box-orient': 'vertical', to use
   * logical properties ('block-axis' instead of 'vertical' and 'inline-axis' instead of
   * 'horizontal') at that time.
   */

  if (typeof truncate === 'object') {
    return {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': `${truncate.lineClamp}`,
      display: '-webkit-box',
      overflow: 'hidden'
    };
  } else if (truncate === true) {
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      'white-space': 'nowrap'
    };
  }
};